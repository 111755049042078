import React from 'react';
import PropTypes from 'prop-types';
import { Blank } from 'grommet-icons';

const IconSheen = (props) => (
  <Blank
    viewBox="0 0 31 31"
    aria-hidden="true"
    focusable="false"
    role="presentation"
    {...props}
    fill="none"
  >
    <g strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="square">
      <path
        stroke={props.color}
        strokeWidth="2"
        d="M21.544 2.193L2.1 21.638M28.686 9.335L9.241 28.78"
      />
      <g>
        <path
          strokeWidth="2"
          stroke={props.color}
          d="M28.686 22.031L9.241 2.586M21.544 29.173L2.1 9.727"
        />
      </g>
    </g>
  </Blank>
);

export default IconSheen;

IconSheen.propTypes = {
  color: PropTypes.string,
};
